import React from 'react';
import { motion as m } from 'framer-motion';
import heroImage from '../assets/hero.jpg';
import AppStoreSVG from './svg/AppStoreSVG';
import heroPhone from '../assets/heroPhone.png';
import classes from './Hero.module.scss';
import { Link } from 'react-router-dom';

const Hero: React.FC = () => {
  const isMobile = window.innerWidth < 1024;

  return (
    <div className={classes.wrapper}>
      <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
        <img alt='preload' src={heroPhone} />
      </div>
      <div className={classes.aspect}>
        <div className={classes.videoWrapper}>
          <video
            className={classes.video}
            autoPlay
            loop
            muted
            poster={heroImage}>
            <source src='/hero.webm' type='video/webm' />
            <source src='/hero.mp4' type='video/mp4' />
          </video>
        </div>
        <div
          className={classes.mobileHero}
          style={{ backgroundImage: `url(${heroImage})` }}
        />
        <div className={classes.content}>
          <div className={classes.gradient} />
          <m.div
            initial={{ x: '-30%', opacity: 0 }}
            animate={{ x: '0%', opacity: 1 }}
            transition={{
              duration: 1,
              delay: isMobile ? 0 : 1.5,
              easings: 'easeInOut'
            }}
            className={classes.text}>
            <h1>Aligning world class physicians in every community</h1>
            <p>
              Broad deficiencies occur in patient transfers between sending and
              receiving hospitals. Doc2doc’s technology optimizes a best
              practice for automating tasks and expediting treatment for better
              patient outcomes.
            </p>
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.8,
                delay: isMobile ? 0 : 2.25,
                easings: 'easeInOut'
              }}
              className={classes.appStoreLink}>
              <Link to='/contact-us'>
                <AppStoreSVG />
              </Link>
            </m.div>
          </m.div>
          <m.div
            initial={{ y: '10%', opacity: 0, scale: 0.95 }}
            animate={{ y: '0%', opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, delay: 2.25, easings: 'easeInOut' }}
            className={classes.heroImage}>
            <img src={heroPhone} alt='phone hero' />
          </m.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
