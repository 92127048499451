import React from "react";
import { motion as m } from "framer-motion";
import { useInView } from "react-intersection-observer";
import SmallBox from "./common/SmallBox";
import CycleSVG from "./svg/CycleSVG";
import LittlePersonSVG from "./svg/LittlePersonSVG";
import AmbulanceSVG from "./svg/AmbulanceSVG";
import classes from "./ThreeBoxes.module.scss";

const ThreeBoxes: React.FC = () => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true
  });

  return (
    <div ref={ref} className={classes.wrap}>
      <m.div
        className={classes.threeboxes}
        variants={{
          visible: {
            transition: {
              when: "beforeChildren",
              staggerChildren: 0.3
            }
          }
        }}
        animate={inView ? "visible" : "hidden"}
      >
        <SmallBox
          key="1"
          title="REDUCE PATIENT COST"
          body="Healthcare is expensive. Even more so if patients are required to travel to receive it. Doc2doc provides a means for patients to reduce time and expenses by providing care in their home community and alleviate unnecessary costs."
          icon={CycleSVG}
        />
        <SmallBox
          key="2"
          title="THE RIGHT TOOL"
          body="Physicians are required to make split second decisions concerning patient care. Let doc2doc give your clinicians the tool to help make these critical assessments."
          icon={AmbulanceSVG}
        />
        <SmallBox
          key="3"
          title="TELE-CONSULT CODES"
          body="Reimbursement of tele-health can be complicated. Make it easy to code, track and be reimbursed for your tele-consultations. Doc2doc goes beyond reimbursement but will increase your ROI."
          icon={LittlePersonSVG}
        />
      </m.div>
    </div>
  );
};

export default ThreeBoxes;
