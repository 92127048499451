import React from "react";

const LittlePersonSVG: React.FC = props => {
  return (
    <svg width={44} height={46} viewBox="0 0 44 46" {...props}>
      <title>{"Group 5"}</title>
      <defs>
        <linearGradient
          x1="64.515%"
          y1="24.741%"
          x2="64.515%"
          y2="0%"
          id="prefix__a"
        >
          <stop stopColor="#F6F7FE" offset="0%" />
          <stop stopColor="#F6F7FE" stopOpacity={0} offset="100%" />
          <stop stopColor="#F6F7FE" stopOpacity={0} offset="100%" />
        </linearGradient>
        <filter
          x="-16.5%"
          y="-14.8%"
          width="133%"
          height="134.4%"
          filterUnits="objectBoundingBox"
          id="prefix__b"
        >
          <feMorphology
            radius={2.5}
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy={10}
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={20}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.520382263 0 0 0 0 0.647627769 0 0 0 0 0.902202219 0 0 0 0.2 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <rect id="prefix__c" x={0} y={0} width={439} height={422} rx={30} />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="url(#prefix__a)"
          d="M0 1757h1600v6296H0z"
          transform="translate(-1136 -5794)"
        />
        <path
          fill="url(#prefix__a)"
          d="M0 2705h1600v6296H0z"
          transform="translate(-1136 -5794)"
        />
        <g transform="translate(-35 -38)">
          <use fill="#000" filter="url(#prefix__b)" xlinkHref="#prefix__c" />
          <use fill="#FFF" xlinkHref="#prefix__c" />
        </g>
        <g fill="#00BCCC">
          <path d="M21.875 18.75c-5.156 0-9.375-4.219-9.375-9.375S16.719 0 21.875 0s9.375 4.219 9.375 9.375-4.219 9.375-9.375 9.375zm0-15.625c-3.438 0-6.25 2.813-6.25 6.25 0 3.438 2.813 6.25 6.25 6.25 3.438 0 6.25-2.813 6.25-6.25 0-3.438-2.813-6.25-6.25-6.25z" />
          <path d="M42.188 45.875H1.562C.625 45.875 0 45.25 0 44.312v-4.687C0 27.594 9.844 17.75 21.875 17.75S43.75 27.594 43.75 39.625v4.688c0 .937-.625 1.562-1.563 1.562zM3.124 42.75h37.5v-3.125c0-10.313-8.438-18.75-18.75-18.75-10.313 0-18.75 8.438-18.75 18.75v3.125z" />
          <path d="M14.563 37.375c-.938 0-1.563-.625-1.563-1.563v-6.25c0-.937.625-1.562 1.563-1.562.937 0 1.562.625 1.562 1.563v6.25c0 .937-.625 1.562-1.563 1.562z" />
          <path d="M17.688 34.25h-6.25c-.938 0-1.563-.625-1.563-1.563 0-.937.625-1.562 1.563-1.562h6.25c.937 0 1.562.625 1.562 1.563 0 .937-.625 1.562-1.563 1.562z" />
        </g>
      </g>
    </svg>
  );
};

export default LittlePersonSVG;
