import React from "react";
import classNames from "classnames";
import classes from "./Button.module.scss";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  alt?: boolean;
}

export const Button: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <button className={classNames(classes.button, className)} {...props}>
      {children}
    </button>
  );
};

export const PrimaryButton: React.FC<Props> = ({
  children,
  className,
  ...props
}) => {
  return (
    <Button className={classNames(classes.primary, className)} {...props}>
      {children}
    </Button>
  );
};

export const SecondaryButton: React.FC<Props> = ({
  children,
  className,
  alt,
  ...props
}) => {
  return (
    <Button
      className={classNames({
        [classes.secondary]: true,
        [classes.alt]: alt,
        [`${className}`]: className
      })}
      {...props}
    >
      {children}
    </Button>
  );
};
