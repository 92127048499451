import React, { useRef, useCallback } from "react";
import {
  motion as m,
  useViewportScroll,
  useMotionValue,
  useTransform
} from "framer-motion";
import StethoscopeSVG from "./svg/StethoscopeSVG";
import EKGLineSVG from "./svg/EKGLineSVG";
import Callout from "./common/Callout";
import FirstAidSVG from "./svg/FirstAidSVG";
import classes from "./EKG.module.scss";

const EKG: React.FC = () => {
  const EKGRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useViewportScroll();
  const x = useMotionValue(0);
  const getInnerHeight = useCallback(() => window.innerHeight, []);
  const lineInput = [0, getInnerHeight() / 1.25];
  const maskOutput = ["-125%", "-45%"];
  const lineOutput = ["150%", "0%"];
  const maskX = useTransform(x, lineInput, maskOutput);
  const lineX = useTransform(x, lineInput, lineOutput);

  scrollY.onChange(v => {
    if (EKGRef.current) {
      const box = EKGRef.current.getBoundingClientRect();
      x.set((box.top - 200 - getInnerHeight()) * -1);
    }
  });

  return (
    <div className={classes.EKG}>
      <Callout
        title="The technology the medical industry needed"
        body={`It's no secret patients heal faster the closer they are to home.
          Doc2doc provides the resources needed to keep patients close to home.
          The medical industry has been waiting for this technology and it's
          here! This is the healthcare delivery model of the future.`}
        icon={StethoscopeSVG}
      />
      <div className={classes.lineWrapper}>
        <div className={classes.graphPaper} />
        <div className={classes.graphGradient} />
        <m.div
          initial={{ x: "-45%", y: "-50%" }}
          style={{ x: maskX }}
          ref={EKGRef}
          className={classes.lineMask}
        >
          <m.div initial={{ x: "100% " }} style={{ x: lineX }}>
            <EKGLineSVG className={classes.line} />
          </m.div>
        </m.div>
      </div>
      <Callout
        title="Better care starts with better solutions"
        body={`Misdiagnosis in patient transfers is unreasonably high. 
          Doc2doc provides the necessary tools to improve the 
          correct diagnosis and streamline the transfer process 
          bridging the communication gap.`}
        icon={FirstAidSVG}
      />
    </div>
  );
};

export default EKG;
