import React from "react";

const CycleSVG: React.FC = props => {
  return (
    <svg width={50} height={50} viewBox="0 0 50 50" {...props}>
      <title>{"Group 5"}</title>
      <defs>
        <linearGradient
          x1="64.515%"
          y1="24.741%"
          x2="64.515%"
          y2="0%"
          id="prefix__a"
        >
          <stop stopColor="#F6F7FE" offset="0%" />
          <stop stopColor="#F6F7FE" stopOpacity={0} offset="100%" />
          <stop stopColor="#F6F7FE" stopOpacity={0} offset="100%" />
        </linearGradient>
        <filter
          x="-16.5%"
          y="-14.8%"
          width="133%"
          height="134.4%"
          filterUnits="objectBoundingBox"
          id="prefix__b"
        >
          <feMorphology
            radius={2.5}
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy={10}
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={20}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.520382263 0 0 0 0 0.647627769 0 0 0 0 0.902202219 0 0 0 0.2 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <rect id="prefix__c" x={0} y={0} width={439} height={422} rx={30} />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="url(#prefix__a)"
          d="M0 1757h1600v6296H0z"
          transform="translate(-95 -5790)"
        />
        <path
          fill="url(#prefix__a)"
          d="M0 2705h1600v6296H0z"
          transform="translate(-95 -5790)"
        />
        <g transform="translate(-35 -34)">
          <use fill="#000" filter="url(#prefix__b)" xlinkHref="#prefix__c" />
          <use fill="#FFF" xlinkHref="#prefix__c" />
        </g>
        <g fill="#00BCCC">
          <path d="M24.84 41.747V38.34a1.112 1.112 0 00-1.896-.782l-4.86 4.86a1.108 1.108 0 000 1.565l4.86 4.86a1.108 1.108 0 001.896-.814v-3.403a19.61 19.61 0 0012.863-4.759 1.436 1.436 0 10-1.897-2.16 16.764 16.764 0 01-10.966 4.04zM24.84 11.34a1.112 1.112 0 001.896.783l4.86-4.86a1.108 1.108 0 000-1.566l-4.86-4.86a1.108 1.108 0 00-1.896.815v3.402a19.61 19.61 0 00-12.863 4.759 1.436 1.436 0 101.897 2.16 16.764 16.764 0 0110.966-4.04v3.407zM48.028 24.84h-3.402a19.599 19.599 0 00-4.759-12.863 1.436 1.436 0 10-2.16 1.897 16.764 16.764 0 014.04 10.966H38.34a1.112 1.112 0 00-.783 1.897l4.86 4.86a1.108 1.108 0 001.565 0l4.86-4.86a1.108 1.108 0 00-.814-1.897zM11.34 24.84a1.112 1.112 0 00.783-1.896l-4.86-4.86a1.108 1.108 0 00-1.566 0l-4.86 4.86a1.108 1.108 0 00.815 1.896h3.402a19.61 19.61 0 004.759 12.863 1.436 1.436 0 102.16-1.897 16.764 16.764 0 01-4.04-10.966h3.407z" />
        </g>
      </g>
    </svg>
  );
};

export default CycleSVG;
