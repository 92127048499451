import React from "react";
import classes from "./Callout.module.scss";

interface Props {
  title: string;
  body: string;
  icon: React.FC;
}

const Callout: React.FC<Props> = ({ title, body, icon: Icon }) => {
  return (
    <div className={classes.callout}>
      <Icon />
      <h2>{title}</h2>
      <p>{body}</p>
    </div>
  );
};

export default Callout;
