import React from 'react';

const AppStoreSVG: React.FC<React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>> = props => {
  return (
    // <a {...props}>
    <svg width={230} height={63} viewBox='0 0 230 63'>
      <title>{'Group 22'}</title>
      <g fill='none' fillRule='evenodd'>
        <path
          d='M2 0h226a2 2 0 012 2v59a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z'
          fill='#00BCCC'
        />
        <path
          d='M66.896 19.216c1.01-1.383 1.692-3.302 1.506-5.216-1.455.067-3.218 1.094-4.262 2.474-.936 1.224-1.756 3.178-1.536 5.054 1.624.143 3.281-.932 4.292-2.312m4.097 11.297c-.04-3.914 3.188-5.792 3.332-5.885-1.813-2.657-4.638-3.021-5.644-3.063-2.404-.244-4.69 1.417-5.91 1.417-1.217 0-3.1-1.381-5.094-1.345-2.62.04-5.036 1.526-6.385 3.876-2.722 4.73-.697 11.737 1.956 15.573 1.297 1.878 2.843 3.987 4.873 3.912 1.954-.078 2.693-1.267 5.057-1.267 2.363 0 3.027 1.267 5.096 1.228 2.103-.039 3.436-1.914 4.723-3.797 1.489-2.179 2.102-4.288 2.138-4.396-.047-.021-4.101-1.577-4.142-6.253'
          fill='#FFF'
        />
        <text
          fontFamily='HelveticaNeue, Helvetica Neue'
          fontSize={14}
          letterSpacing={-0.4}
          fill='#FFF'>
          <tspan x={87.042} y={28}>
            {'Available on the'}
          </tspan>
        </text>
        <text
          fontFamily='HelveticaNeue-Bold, Helvetica Neue'
          fontSize={16}
          fontWeight='bold'
          letterSpacing={-0.457}
          fill='#FFF'>
          <tspan x={86.121} y={44}>
            {'App Store'}
          </tspan>
        </text>
      </g>
    </svg>
    // </a>
  );
};

export default AppStoreSVG;
