import React from "react";
import classNames from "classnames";
import classes from "./Form.module.scss";
import { Field } from "formik";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
}

interface TextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: string;
}

export const PrimaryInput: React.FC<Props> = ({
  children,
  className,
  placeholder,
  ...props
}) => {
  return (
    <Field {...props}>
      {({ field, meta }: any) => (
        <>
          <input
            className={classNames({
              [classes.primaryInput]: true,
              [classes.hasError]: meta.touched && meta.error,
              [`${className}`]: className
            })}
            placeholder={placeholder}
            {...field}
          />
          {meta.touched && meta.error && meta.error}
        </>
      )}
    </Field>
  );
};

export const SecondaryInput: React.FC<Props> = ({
  children,
  label,
  className,
  placeholder,
  ...props
}) => {
  return (
    <Field {...props}>
      {({ field, meta }: any) => (
        <label className={classes.secondaryLabel} htmlFor={field.name}>
          {label && <div className={classes.label}>{label}</div>}
          <input
            id={field.name}
            className={classNames({
              [classes.secondaryInput]: true,
              [classes.hasError]: meta.touched && meta.error,
              [`${className}`]: className
            })}
            placeholder={placeholder}
            {...field}
          />
          <small>{meta.touched && meta.error && meta.error}</small>
        </label>
      )}
    </Field>
  );
};

export const SecondaryTextarea: React.FC<TextareaProps> = ({
  children,
  label,
  className,
  placeholder,
  ...props
}) => {
  return (
    <Field {...props}>
      {({ field, meta }: any) => (
        <label className={classes.secondaryLabel} htmlFor={field.name}>
          {label && <div className={classes.label}>{label}</div>}
          <textarea
            id={field.name}
            className={classNames(classes.secondaryTextArea, className)}
            placeholder={placeholder}
            {...field}
          />
          {meta.touched && meta.error && meta.error}
        </label>
      )}
    </Field>
  );
};
