import React from 'react';
import Logo from './svg/LogoSVG';
import { PrimaryButton } from './ui/Button';
import { PrimaryInput } from './ui/Form';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import classes from './Footer.module.scss';

const Footer: React.FC = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.content}>
        <section>
          <Link to='/'>
            <Logo className={classes.logo} />
          </Link>
        </section>
        <section>
          <h5>Features</h5>
          <ul>
            <li>
              <span>Medical Imaging</span>
            </li>
            <li>
              <span>Tele-Consultation</span>
            </li>
            <li>
              <span>Seamless Transfers</span>
            </li>
            <li>
              <span>Web RTC Video calling</span>
            </li>
          </ul>
        </section>
        <section>
          <h5>Company</h5>
          <ul>
            <li>
              <Link to='/contact-us'>FAQ</Link>
            </li>
            <li>
              <Link to='/contact-us'>Contact Us</Link>
            </li>
            <li>
              <Link to='/contact-us'>Careers</Link>
            </li>
            <li>
              <Link to='/legal'>Terms</Link>
            </li>
            <li>
              <a href={process.env.REACT_APP_LINKEDIN_URL}>Linkedin</a>
            </li>
            <li>
              <a href='/whitepaper.pdf'>Whitepaper</a>
            </li>
          </ul>
        </section>
        <section>
          <h5>Stay in the Know</h5>
          <ul>
            <li>Sign up for Doc2Doc news and app updates</li>
            <li>
              <Formik
                initialValues={{ email: '' }}
                onSubmit={(values: { email: string }) =>
                  console.log(values.email)
                }>
                {() => (
                  <Form>
                    <PrimaryInput
                      className={classes.signUpInput}
                      name='email'
                      type='email'
                      placeholder='your@email.com'
                    />
                    <PrimaryButton type='submit'> Stay Updated</PrimaryButton>
                  </Form>
                )}
              </Formik>
            </li>
          </ul>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
