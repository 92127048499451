import React from 'react';
import LogoSVG from './svg/LogoSVG';
import classes from './Header.module.scss';
import { PrimaryButton, SecondaryButton } from './ui/Button';
import { Link } from 'react-router-dom';

interface Props {
  alt?: boolean;
}

const Header: React.FC<Props> = ({ alt }) => {
  return (
    <nav className={classes.wrapper}>
      <Link to='/' className={classes.logoWrapper}>
        {alt ? <img alt="Header" src="/d2d_banner.png" style={{width: "211px"}} /> : <LogoSVG />}
      </Link>
      <div className={classes.buttonWrapper}>
        <a href={process.env.REACT_APP_LOGIN_URL}>
          <SecondaryButton alt={alt}>Login</SecondaryButton>
        </a>
        <Link to='/contact-us'>
          <PrimaryButton>Get The App</PrimaryButton>
        </Link>
      </div>
    </nav>
  );
};

export default Header;
