import React from "react";

const Globe: React.FC = () => {
  return (
    <svg width={56} height={66} viewBox="0 0 56 66">
      <title>{"Group"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          fill="url(#prefix__a)"
          d="M0 1757h1600v6296H0z"
          transform="translate(-110 -6515)"
        />
        <path
          fill="url(#prefix__a)"
          d="M0 2705h1600v6296H0z"
          transform="translate(-110 -6515)"
        />
        <path fill="#1E1E30" d="M-111-117h801v743h-801z" />
        <g
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path d="M23.5 1.955c12.426 0 22.5 10.074 22.5 22.5s-10.074 22.5-22.5 22.5S1 36.881 1 24.455s10.074-22.5 22.5-22.5z" />
          <path d="M45.775 2.183c12.3 12.303 12.3 32.247 0 44.547-12.3 12.3-32.247 12.3-44.547 0M23.5 55.955v9M5.5 64.955h36" />
          <path d="M2.872 15.455H7.75c2.484 0 4.5 2.016 4.5 4.5v4.887c0 1.194.474 2.34 1.317 3.183l3.465 3.465a4.497 4.497 0 011.089 4.605l-.927 2.778a4.512 4.512 0 01-1.569 2.175l-3.648 2.736M30.7 3.137a284.965 284.965 0 01-3.678 2.877 4.501 4.501 0 00-.855 6.264l2.733 3.627a4.497 4.497 0 003.6 1.8h2.637c1.194 0 2.337.477 3.18 1.32l3.465 3.465a4.503 4.503 0 004.2 1.2" />
        </g>
      </g>
    </svg>
  );
};

export default Globe;
