import React from 'react';
import GlobeSVG from './svg/GlobeSVG';
import { PrimaryButton, SecondaryButton } from './ui/Button';
import { motion as m, useViewportScroll, useTransform } from 'framer-motion';
import doctor from './../assets/doctor.png';
import classes from './CTABlock.module.scss';
import { Link } from 'react-router-dom';

const CTABlock: React.FC = () => {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0.8, 1], [0.6, 0.9]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftbox}>
        <div className={classes.text}>
          <GlobeSVG />
          <h2>The world is our neighborhood</h2>
          <p>
            No longer is zip code a predictor of a patient's access to
            healthcare. Doc2doc knows no zip code and is breaking the barriers
            of location discrimination. Doc2doc is the disruptive technology
            that will save patient lives in the most critical moments of care.
          </p>
          <a href={process.env.REACT_APP_LOGIN_URL}>
            <SecondaryButton style={{ marginRight: "12px" }}>Login</SecondaryButton>
          </a>
          <Link to='/contact-us'>
            <PrimaryButton>Get The App</PrimaryButton>
          </Link>
        </div>
      </div>
      <div className={classes.rightbox}>
        <m.div
          className={classes.doctor}
          style={{
            backgroundImage: `url(${doctor})`,
            scale
          }}
        />
      </div>
    </div>
  );
};

export default CTABlock;
