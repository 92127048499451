import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';

import classes from './Legal.module.scss';

const getLegalCopy = async () => {
  const { data } = await axios.get(
    process.env.REACT_APP_LOGIN_URL + 'api/LegalDocuments?api-version=1.0&%24expand=legalDocumentVersion&%24filter=name%20eq%20%27Terms%20and%20Conditions%27&%24count=false'
  );
  return data.value[0].legalDocumentVersion.content;
};
const Legal: React.FC = () => {
  const { status, data } = useQuery<string, any>('legalcopy', getLegalCopy);
  function returnHtmlContent() {
    return { __html: data || '' };
  }
  return (
    <div className={classes.legal}>
      <div className={classes.content}>
        <h4>Doc2Doc Legal</h4>
        <h2>Terms &amp; Conditions</h2>
        {status === 'loading' ? (
          <p>Loading</p>
        ) : (
          <div dangerouslySetInnerHTML={returnHtmlContent()} />
        )}
      </div>
    </div>
  );
};
export default Legal;
