import React from "react";

const FirstAidSVG: React.FC = props => {
  return (
    <svg width={78} height={78} viewBox="0 0 78 78" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
          <rect
            stroke="#DC4343"
            strokeWidth={2}
            fill="#DC4343"
            width={76}
            height={76}
            rx={2}
          />
        </g>
        <path
          d="M32.28 20a2.719 2.719 0 00-2.7 2.7v3.24c0 .186.015.365.05.54H18.78A3.795 3.795 0 0015 30.26v22.68a3.795 3.795 0 003.78 3.78h39.96a3.795 3.795 0 003.78-3.78V30.26a3.795 3.795 0 00-3.78-3.78H47.89a2.68 2.68 0 00.05-.54V22.7c0-1.477-1.224-2.7-2.7-2.7H32.28zm0 2.16h12.96c.319 0 .54.221.54.54v3.24c0 .319-.221.54-.54.54H32.28a.516.516 0 01-.54-.54V22.7c0-.319.222-.54.54-.54zm-13.5 6.48h39.96c.916 0 1.62.705 1.62 1.62v22.68c0 .915-.704 1.62-1.62 1.62H18.78c-.915 0-1.62-.705-1.62-1.62V30.26c0-.915.705-1.62 1.62-1.62zm18.776 7.56h2.588v2.756c0 .9 0 .9.9.9H43.8v2.588h-2.756c-.9 0-.9 0-.9.9V46.1h-2.588v-2.756c0-.9 0-.9-.9-.9H33.9v-2.588h2.756c.9 0 .9 0 .9-.9V36.2z"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#FFF"
        />
      </g>
    </svg>
  );
};

export default FirstAidSVG;
