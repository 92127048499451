import React, { useLayoutEffect } from 'react';
import Header from './Header';
import Hero from './Hero';
import EKG from './EKG';
import ThreeBoxes from './ThreeBoxes';
import CTABlock from './CTABlock';
import Footer from './Footer';
import classes from './App.module.scss';
import { Route, Switch, useLocation } from 'react-router-dom';
import ContactUs from './ContactUs';
import Legal from './Legal';
import { StaticKitProvider } from '@statickit/react';

import slide1Image from "../assets/slide1.png";
import slide2Image from "../assets/slide2.png";
import slide3Image from "../assets/slide3.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const App: React.FC = () => {
  const { pathname } = useLocation();
  const is_matched_path =
    ['contact-us', 'legal'].filter((x) => pathname.includes(x)).length > 0;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <StaticKitProvider site='cefe138e4d04'>
      <main className={classes.wrapper}>
        <Header alt={is_matched_path} />
        <Switch>
          <Route exact path='/'>
            <Hero />
            <EKG />
            <Carousel showStatus={false} showThumbs={false} showIndicators={false} showArrows={false} stopOnHover={false} infiniteLoop={true} autoPlay={true}>
            <div>
                <img alt="slide1" src={slide1Image} />
            </div>
            <div>
                <img alt="slide2" src={slide2Image} />
            </div>
            <div>
                <img alt="slide3" src={slide3Image} />
            </div>
          </Carousel>
            <ThreeBoxes />
            <CTABlock />
          </Route>
          <Route path='/contact-us'>
            <ContactUs />
          </Route>
          <Route path='/legal'>
            <Legal />
          </Route>
        </Switch>
        <Footer />
      </main>
    </StaticKitProvider>
  );
};

export default App;
