import React from "react";
import { motion as m } from "framer-motion";
import classes from "./SmallBox.module.scss";

interface Props {
  title: string;
  body: string;
  icon: React.FC;
}

const SmallBox: React.FC<Props> = ({ title, body, icon: Icon }) => {
  const animation = {
    transition: {
      duration: 1.5,
      easings: "easeInOut"
    },
    variants: {
      hidden: {
        opacity: 0
      },
      visible: {
        opacity: 1
      }
    },
    initial: "hidden"
  };

  return (
    <m.div {...animation} className={classes.smallbox}>
      <header>
        <Icon />
      </header>
      <h2>{title}</h2>
      <p>{body}</p>
    </m.div>
  );
};

export default SmallBox;
