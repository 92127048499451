import React from "react";

const StethoscopeSVG: React.FC = props => {
  return (
    <svg width={78} height={78} viewBox="0 0 78 78" {...props}>
      <title>{"Group 7"}</title>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <rect
          stroke="#DC4343"
          strokeWidth={2}
          fill="#DC4343"
          width={76}
          height={76}
          rx={2}
        />
        <path
          d="M26.686 17.245V14H20v12.224c0 2.846 1.001 5.596 2.82 7.745a11.383 11.383 0 007.075 3.935v13.413c0 4.175 2.166 8.03 5.684 10.118a11.104 11.104 0 0011.37 0c3.515-2.088 5.682-5.943 5.682-10.118v-7.246c2.87-.813 4.714-3.68 4.315-6.711-.399-3.032-2.917-5.292-5.894-5.292-2.975 0-5.494 2.26-5.892 5.292-.398 3.031 1.443 5.898 4.313 6.711v7.246c0 3.015-1.564 5.8-4.103 7.308a8.025 8.025 0 01-8.212 0c-2.54-1.508-4.105-4.293-4.105-7.308V37.904a11.393 11.393 0 007.043-3.952 11.98 11.98 0 002.798-7.728V14h-6.683v3.245h3.526v8.979c0 3.042-1.58 5.854-4.145 7.377a8.1 8.1 0 01-8.29 0c-2.563-1.523-4.144-4.335-4.144-7.377v-8.979h3.528zM48.12 38.122c0-1.218.722-2.318 1.828-2.785a2.919 2.919 0 013.225.654c.847.862 1.1 2.16.642 3.287a2.957 2.957 0 01-2.734 1.86c-1.637 0-2.961-1.349-2.961-3.016h0z"
          stroke="#FFF"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

export default StethoscopeSVG;
