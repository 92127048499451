import React from 'react';
import { motion as m, AnimatePresence } from 'framer-motion';
import { PrimaryButton } from './ui/Button';
import classes from './ContactUs.module.scss';
import { Link } from 'react-router-dom';
import { ValidationError, useForm } from '@statickit/react';
import classNames from 'classnames';
import formClasses from './ui/Form.module.scss';

const ContactUs: React.FC = () => {
  const [state] = useForm('contactForm');
  const hasFieldError = (fieldName: string) => {
    const matches = state.errors.filter(error => {
      return error.field === fieldName;
    });
    return matches.length;
  };
  return (
    <div className={classes.contactUs}>
      <div className={classes.formModal}>
      <form
        action={process.env.REACT_APP_FORMSPREE_FORM_ENDPOINT}
        method="POST"
      >
          <AnimatePresence exitBeforeEnter>
            {state.succeeded ? (
              <m.div
                key='after'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={classes.thankYouMessage}>
                <h2>Thank you!</h2>
                <p>Thanks for reaching out! We’ll get back to you shortly</p>
                <Link to='/'>
                  <PrimaryButton className={classes.thankYouButton}>
                    Back to Home
                  </PrimaryButton>
                </Link>
              </m.div>
            ) : (
              <m.div
                key='before'
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <h2>Contact Us</h2>
                <div className={classes.row}>
                  <label className={formClasses.secondaryLabel} htmlFor='name'>
                    <div className={classes.label}>Name*</div>
                    <input
                      name='name'
                      className={classNames({
                        [formClasses.secondaryInput]: true,
                        [formClasses.hasError]:
                          state.errors.length && hasFieldError('name'),
                        [classes.input]: true
                      })}
                      placeholder='Enter Name'
                    />
                    <ValidationError
                      field='name'
                      prefix='Name'
                      errors={state.errors}
                    />
                  </label>
                  ​
                  <label className={formClasses.secondaryLabel} htmlFor='email'>
                    <div className={classes.label}>Email Address*</div>
                    <input
                      name='email'
                      className={classNames({
                        [formClasses.secondaryInput]: true,
                        [formClasses.hasError]:
                          state.errors.length && hasFieldError('email'),
                        [classes.input]: true
                      })}
                      placeholder='Enter Email Address'
                    />
                    <ValidationError
                      field='email'
                      prefix='Email'
                      errors={state.errors}
                    />
                  </label>
                  ​
                </div>
                <div className={classes.row}>
                  <label className={formClasses.secondaryLabel} htmlFor='phone'>
                    <div className={classes.label}>Phone Number</div>
                    <input
                      name='phone'
                      className={classNames({
                        [formClasses.secondaryInput]: true,
                        [formClasses.hasError]:
                          state.errors.length && hasFieldError('phone'),
                        [classes.input]: true
                      })}
                      placeholder='Enter Phone Number'
                    />
                    <ValidationError
                      field='phone'
                      prefix='Phone'
                      errors={state.errors}
                    />
                  </label>
                  ​
                  <label className={formClasses.secondaryLabel} htmlFor='org'>
                    <div className={classes.label}>Organization</div>
                    <input
                      name='org'
                      className={classNames({
                        [formClasses.secondaryInput]: true,
                        [formClasses.hasError]:
                          state.errors.length && hasFieldError('org'),
                        [classes.input]: true
                      })}
                      placeholder='Organization Name'
                    />
                    <ValidationError
                      field='org'
                      prefix='Org'
                      errors={state.errors}
                    />
                  </label>
                  ​
                </div>
                <div className={classes.row}>
                  <label
                    className={formClasses.secondaryLabel}
                    htmlFor='message'>
                    <div className={classes.label}>Message</div>
                    <textarea
                      name='message'
                      className={formClasses.secondaryTextArea}
                      placeholder='Enter message...'></textarea>
                    <ValidationError
                      field='message'
                      prefix='Message'
                      errors={state.errors}
                    />
                  </label>
                </div>
                <div className={classes.row}>
                  <PrimaryButton
                    type='submit'
                    disabled={state.submitting}
                    className={classes.submitButton}>
                    Send
                  </PrimaryButton>
                </div>
                ​
              </m.div>
            )}
          </AnimatePresence>
        </form>
      </div>
      <div className={classes.sidebar}>
        <div>
          <h4>Phone</h4>
          <a href='tel:+18013812101'>801-663-3983</a>
        </div>
        <div>
          <h4>Email Address</h4>
          <a href={`email:${process.env.REACT_APP_CONTACT_EMAIL}`} >{process.env.REACT_APP_CONTACT_EMAIL}</a>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
